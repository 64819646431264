import { styled } from '@linaria/react';

export type AvatarSize = 'small' | 'normal';
export type AvatarBorder = 'dashed' | 'surface' | 'none';

export const AvatarWrapper = styled.div<{
  backgroundColor: string;
  border: AvatarBorder;
  size: AvatarSize;
  overlapping?: boolean;
}>`
  position: relative;
  width: ${({ size }) => (size === 'small' ? '2rem' : '2.5rem')};
  height: ${({ size }) => (size === 'small' ? '2rem' : '2.5rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 12px;
  font-family: var(--font-sans);
  font-weight: 700;
  border-radius: var(--radius-full);
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ overlapping }) => (overlapping ? '0 0 0 -5px' : 'none')};
  border: ${({ border }) =>
    border === 'dashed'
      ? '1px dashed var(--border-strong)'
      : border === 'surface'
      ? '2px solid var(--surface)'
      : 'none'};

  &:hover {
    box-shadow: ${({ overlapping }) => (overlapping ? 'var(--elevation-bottom-3)' : 'none')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--radius-full);
  }

  span {
    text-transform: uppercase;
  }
`;
