import { RMAvatarSharingRootStyled } from './RMAvatarSharingRoot.styles';

type RMAvatarSharingRootProps = {
  children: React.ReactNode;
  className?: string;
};

export function RMAvatarSharingRoot({ className, children }: RMAvatarSharingRootProps) {
  return <RMAvatarSharingRootStyled className={className}>{children}</RMAvatarSharingRootStyled>;
}
