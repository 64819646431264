import { useMemo } from 'react';
import { AclGroupRole } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';
import { useQueries } from '@tanstack/react-query';
import Crc32 from 'crc-32';

import { useServices } from '@/Services';
import { useAclGroupInviteesQuery, usePrimaryAclGroup } from '@/services/api/acl';
import { getNameInitials } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { Colors } from '@/styles/base/colors';
import { areAllQueriesLoaded } from '@/utils/query';

import { ActiveMember, InvitedMember, Member } from '../types';

function sortMembers(a: Member, b: Member): number {
  const typeOrder: Record<Member['type'], number> = {
    storyteller: 1,
    host: 2,
    collaborator: 3,
    invited: 4,
  };

  // Compare member types based on the defined order
  const typeComparison = typeOrder[a.type] - typeOrder[b.type];
  if (typeComparison !== 0) {
    return typeComparison;
  }

  // If both members have the same type, handle other sorting criteria
  // For collaborators , sort alphabetically by full name
  if (a.type === 'collaborator' && b.type === 'collaborator') {
    if (a.name && !b.name) {
      return -1;
    }
    if (!a.name && b.name) {
      return 1;
    }

    if (a.name && b.name) {
      return a.name.full.localeCompare(b.name.full);
    }

    return a.email.localeCompare(b.email);
  }

  // For invited users, sort by email
  if (a.type === 'invited' && b.type === 'invited') {
    return a.email.localeCompare(b.email);
  }

  // Otherwise, keep the order as is
  return 0;
}

const collaboratorColors = [Colors.yellow[30], Colors.aqua[70], Colors.red[80], Colors.red[40], Colors.blend[69]];

export function useProjectMembers(projectId: string | null): Member[] | null {
  const { entityCacheManagerService, aclService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const projectGroup = usePrimaryAclGroup(projectQuery.data?.acl, EntityType.PROJECT);
  const allMembersQuery = useQueries({
    queries:
      projectGroup?.members.map(({ id }) => {
        return entityCacheManagerService.buildEntityQuery(EntityType.ACL_GROUP_MEMBER, id, () => {
          return aclService.getGroupMember(id);
        });
      }) ?? [],
  });

  const inviteesQuery = useAclGroupInviteesQuery(projectGroup?.id);
  const allInviteesQuery = useQueries({
    queries:
      inviteesQuery.data?.map((id) => {
        return entityCacheManagerService.buildEntityQuery(EntityType.ACL_GROUP_INVITEE, id, () => {
          return aclService.getGroupInvitee(id);
        });
      }) ?? [],
  });

  const members = useMemo<Member[] | null>(() => {
    const allQueries = [...allMembersQuery, inviteesQuery, ...allInviteesQuery];
    if (areAllQueriesLoaded(allQueries) === false) {
      return null;
    }

    const activeMembers: ActiveMember[] = allMembersQuery.map((query) => {
      if (!query.data) {
        throw new Error('Query data cannot be null');
      }
      let type: ActiveMember['type'];
      let color: string;
      switch (query.data.role) {
        case AclGroupRole.OWNER: {
          type = 'host';
          color = Colors.blend[70];
          break;
        }
        case AclGroupRole.ADMIN: {
          type = 'storyteller';
          color = Colors.spruce[40];
          break;
        }
        default: {
          type = 'collaborator';
          const hash = Crc32.str(query.data.user.id) >>> 0;
          color = collaboratorColors[hash % collaboratorColors.length];
          break;
        }
      }

      return {
        aclGroupId: query.data.groupId,
        aclGroupMemberId: query.data.id,
        aclRole: query.data.role,
        userId: query.data.user.id,
        type,
        email: query.data.user.communicationChannels.email,
        name: query.data.user.name,
        initials: query.data.user.name ? getNameInitials(query.data.user.name) : null,
        avatarUrl: query.data.user.avatarUrl,
        avatarColor: color,
      };
    });

    const invitedMembers: InvitedMember[] = allInviteesQuery.map((query) => {
      if (!query.data) {
        throw new Error('Query data cannot be null');
      }
      return {
        aclGroupId: query.data.groupId,
        aclGroupInviteeId: query.data.id,
        aclRole: query.data.role,
        type: 'invited',
        email: query.data.email,
      };
    });

    return [...activeMembers, ...invitedMembers].sort(sortMembers);
  }, [allInviteesQuery, allMembersQuery, inviteesQuery]);

  return members;
}
