import { RMTooltip } from '../RMTooltip/RMTooltip';

import { AvatarBorder, AvatarSize, AvatarWrapper } from './RMAvatarSharingItem.styles';

type RMAvatarSharingItemProps = {
  imageURL?: string | null;
  text: string;
  border?: AvatarBorder;
  size?: AvatarSize;
  backgroundColor: string;
  tooltip?: string;
  overlapping?: boolean;
  onClick?: () => void;
};

export function RMAvatarSharingItem({
  backgroundColor,
  border,
  imageURL,
  text,
  size,
  tooltip,
  overlapping,
  onClick,
}: RMAvatarSharingItemProps) {
  const isNumber = /^\d+$/.test(text);
  const displayText = isNumber ? text : text.slice(0, 2).toUpperCase();

  return (
    <RMTooltip message={tooltip} side="bottom">
      <AvatarWrapper
        backgroundColor={backgroundColor}
        border={border ?? 'none'}
        size={size ?? 'normal'}
        overlapping={overlapping}
        onClick={onClick}
      >
        {imageURL ? <img src={imageURL} alt={displayText} /> : <span>{displayText}</span>}
      </AvatarWrapper>
    </RMTooltip>
  );
}
